class Caption {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.captionDesktop = this.root.getAttribute('data-caption');
        this.captionMobile = this.root.getAttribute('data-caption-mobile');

        return true;
    }

    init() {
        if (!this.root) return;

        this.onResize();
        this.setResize();
    }

    setResize() {
        if (!this.root) return;
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        const { innerWidth } = window;

        if (innerWidth > 799) {
            this.root.innerHTML = this.captionDesktop;
        } else {
            this.root.innerHTML = this.captionMobile;
        }
    }
}

export default function initCaptions() {
    const sections = document.querySelectorAll('[data-caption]');
    if (sections) sections.forEach(item => new Caption(item));
}
